import { HexColorInput, HexColorPicker } from 'react-colorful';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { getPlotPalette } from '@components/ColorPaletteUtil';
import { ThemeColor } from '@models/PlotConfigs';
import cn from 'classnames';
import { isDefined } from '@util/TypeGuards';

const PalettePicker = ({
    colors,
    setColor,
    label,
    className,
    color,
}: {
    colors: string[];
    label: ReactNode;
    className?: string;
    setColor: Dispatch<SetStateAction<string>>;
    color: string;
}) => {
    return (
        <div className={cn('py-2', className)}>
            <p className="mb-1 text-sm font-semibold tracking-tight text-gray-500">{label}</p>
            <div className="grid grid-cols-8 gap-2">
                {colors.map((paletteColor) => (
                    <button
                        type="button"
                        key={paletteColor}
                        className={cn('col-span-1 h-6 w-6 cursor-pointer rounded', {
                            'ring-[3px] ring-indigo-600 ring-offset-2': color === paletteColor,
                        })}
                        style={{ background: paletteColor }}
                        onClick={() => setColor(paletteColor)}
                    />
                ))}
            </div>
        </div>
    );
};
type Props = {
    setColor: Dispatch<SetStateAction<string>>;
    color: string;
    customColors?: string[];
    customColorsLabel?: string;
    secondaryCustomColors?: string[];
    secondaryCustomColorsLabel?: string;
    hideThemes?: boolean;
    themeColor?: ThemeColor;
};
const ColorPicker = ({
    color,
    setColor,
    customColors = [],
    customColorsLabel = 'Favorite colors',
    secondaryCustomColors = [],
    secondaryCustomColorsLabel = 'Organization colors',
    hideThemes = false,
    themeColor,
}: Props) => {
    const paletteCool = getPlotPalette(ThemeColor.cool);
    const paletteWarm = getPlotPalette(ThemeColor.warm);
    const paletteGray = getPlotPalette(ThemeColor.gray);
    const paletteBatlow = getPlotPalette(ThemeColor.batlow);
    const paletteRoma = getPlotPalette(ThemeColor.roma);

    const selectedTheme = isDefined(themeColor) ? getPlotPalette(themeColor) : null;

    return (
        <div className="pluto-color-picker mb-4 space-y-4 pt-4">
            <HexColorPicker color={color} onChange={(newColor) => setColor(newColor)} />
            <div className="form-field relative block">
                <div className="absolute bottom-0 left-2 top-0 z-20 flex items-center space-x-1 text-gray-600">
                    <span>Hex</span>
                </div>
                <HexColorInput color={color} onChange={setColor} prefixed className="input field-input !pl-10" />
                <div className="absolute bottom-0 right-2 top-0 z-20 flex items-center space-x-1 text-gray-600">
                    <span
                        className="inline-block h-5 w-5 shrink-0 rounded border border-gray-200"
                        style={{ background: color }}
                    ></span>
                </div>
            </div>
            <div className="-mx-2 divide-y divide-gray-200 overflow-y-auto px-2">
                {selectedTheme && (
                    <div className="pt-2">
                        <p className="font-semibold tracking-tight text-gray-500">Theme colors</p>
                        <PalettePicker
                            colors={selectedTheme.colors.map((p) => p.color)}
                            setColor={setColor}
                            label=""
                            color={color}
                        />
                    </div>
                )}

                {customColors.length > 0 && (
                    <div className="pt-2">
                        <p className=" font-semibold tracking-tight text-gray-500">{customColorsLabel}</p>
                        <PalettePicker colors={customColors} setColor={setColor} label="" color={color} />
                    </div>
                )}

                {secondaryCustomColors.length > 0 && (
                    <div className="pt-2">
                        <p className=" font-semibold tracking-tight text-gray-500">{secondaryCustomColorsLabel}</p>
                        <PalettePicker colors={secondaryCustomColors} setColor={setColor} label="" color={color} />
                    </div>
                )}

                {!hideThemes && (
                    <div className="pt-2">
                        <p className="font-semibold tracking-tight text-gray-500">Available themes</p>
                        <div className="max-h-24">
                            <PalettePicker
                                colors={paletteCool.colors.map((p) => p.color)}
                                setColor={setColor}
                                label=""
                                color={color}
                            />
                            <PalettePicker
                                colors={paletteWarm.colors.map((p) => p.color)}
                                setColor={setColor}
                                label=""
                                color={color}
                            />
                            <PalettePicker
                                colors={paletteGray.colors.map((p) => p.color)}
                                setColor={setColor}
                                label=""
                                color={color}
                            />
                            <PalettePicker
                                colors={paletteBatlow.colors.map((p) => p.color)}
                                setColor={setColor}
                                label=""
                                color={color}
                            />
                            <PalettePicker
                                colors={paletteRoma.colors.map((p) => p.color)}
                                setColor={setColor}
                                label=""
                                color={color}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ColorPicker;
